import {
  Button,
  Card,
  Table,
  Modal,
  message,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
  Tag,
  Popconfirm,
  DatePicker,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useLocale } from "../../../locales";
import {
  apiApprovePromotion,
  apiDeletePromotion,
  apiGenCodePromotion,
  apiListPromotion,
  apiPickCodePromotion,
} from "../../../services/promotion.api";
import { IPromotion, IPropsPickCode } from "../../../types/promotion.type";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import CreateFormPromotion from "../../../components/HFormCreatePromotion";
import FormUpdatePromotion from "../../../components/HFormUpdatePromotion";
import moment from "moment";
import { RouteComponentProps, Link } from "@reach/router";
import { tableColumnTextFilterConfig } from "../../../utils/filterTable";
import { tableColumnTextFilterDateConfig } from "../../../utils/filterDateTable";
import useApp from "../../../context/app.context";
// import FormSearchPromotion from "../../../components/HFormSearchPromotion";

type Data = {
  key: string;
  name: string;
};

export default function PromotionScene(_: RouteComponentProps) {
  const { t } = useLocale();
  const {
    stateAuth: { chanelId },
  } = useApp();
  const [form] = Form.useForm();
  const [data, setData] = useState<IPromotion[]>([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalUpdate, setIsModalUpdate] = useState(false);
  const [dataUpdate, setDataUpdate] = useState<IPromotion>();
  const [visibleCode, setVisibleCode] = useState(false);
  const [idPromotion, setIdPromotion] = useState("");
  const [notiGenCode, setNotiGenCode] = useState(false);
  const [startDate, setStartDate] = useState("");

  const fetchListPromotion = useCallback(async () => {
    setLoading(true);
    const res: IPromotion[] = await apiListPromotion(chanelId);
    if (res) {
      setLoading(false);
      setIsModalVisible(false);
      setIsModalUpdate(false);
      setData(res);
    }
  }, [chanelId]);

  useEffect(() => {
    if (chanelId) {
      fetchListPromotion();
    }
  }, [chanelId]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancelUpdate = () => {
    setIsModalUpdate(false);
  };

  const deletePromotion = async (id: string) => {
    setLoading(true);
    await apiDeletePromotion(id, chanelId);
    setLoading(false);
  };

  const onFinish = async (values: IPropsPickCode) => {
    setLoading(true);
    await apiPickCodePromotion(
      idPromotion,
      {
        ...values,
        export_file: true,
      },
      chanelId
    )
      .then((res) => {
        setVisibleCode(false);
        if (res.data.amount === 0) {
          message.error({ content: "Số lượng mã đã hết" });
        } else {
          message.success({ content: "Success!" });
        }
      })
      .catch(() => {
        message.error({ content: "Error!" });
      });
    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const genCodePromotion = async (id: string) => {
    setNotiGenCode(true);
    await apiGenCodePromotion(id, chanelId).then(() => {
      form.resetFields();
    });
  };

  const approvePromotion = async (id: string) => {
    const res = await apiApprovePromotion(
      id,
      { starts_date: startDate },
      chanelId
    );
    if (res.status === 200) {
      message.success({ content: "Success!" });
      fetchListPromotion();
    } else {
      message.error({ content: "Error!" });
    }
  };

  const columns: any = [
    {
      title: t({ id: "app.promotion_tool.promotions.name" }),
      key: "name",
      fixed: "left",
      render: (text: IPromotion) => (
        <Link to={`/promotions/${text.id}`}>{text.name}</Link>
      ),
      ...tableColumnTextFilterConfig<Data>(),
      onFilter: (value: any, record: any) => {
        return record.name
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    },
    {
      title: t({ id: "app.promotion_tool.promotions.code" }),
      key: "code",
      fixed: "left",
      render: (text: IPromotion) => text.code,
      ...tableColumnTextFilterConfig<Data>(),
      onFilter: (value: any, record: IPromotion) => {
        return record.code
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    },
    {
      title: t({ id: "app.promotion_tool.promotions.redemption_type" }),
      key: "redemption_type",
      render: (text: any, record: IPromotion) => {
        switch (record.redemption_type) {
          case "CO":
            return "Coupon";
          case "VO":
            return "Voucher";
        }
      },
      filters: [
        {
          text: "Coupon",
          value: "CO",
        },
        {
          text: "Voucher",
          value: "VO",
        },
      ],
      onFilter: (value: any, record: IPromotion) =>
        record.redemption_type.startsWith(value),
      filterSearch: true,
    },
    {
      title: t({ id: "app.promotion_tool.promotions.expected_date" }),
      key: "expected_date",
      render: (text: IPromotion) => moment(text.expected_date).format("L"),
      ...tableColumnTextFilterDateConfig<Data>(),
      onFilter: (value: any, record: IPromotion) => {
        return moment(record.expected_date)
          .format("L")
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    },
    {
      title: t({ id: "app.promotion_tool.promotions.expires_date" }),
      key: "expires_date",
      render: (text: IPromotion) => moment(text.expires_date).format("L"),
      ...tableColumnTextFilterDateConfig<Data>(),
      onFilter: (value: any, record: IPromotion) => {
        return moment(record.expires_date)
          .format("L")
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    },
    {
      title: t({ id: "app.promotion_tool.promotions.discount_type" }),
      key: "discount_type",
      render: (text: any, record: IPromotion) => {
        switch (record.discount_type) {
          case "FL":
            return "Tiền";
          case "PE":
            return "%";
        }
      },
      filters: [
        {
          text: "Tiền",
          value: "FL",
        },
        {
          text: "%",
          value: "PE",
        },
      ],
      onFilter: (value: any, record: IPromotion) =>
        record.discount_type.startsWith(value),
      filterSearch: true,
    },
    {
      title: t({ id: "app.promotion_tool.promotions.redemption_value" }),
      key: "redemption_value",
      render: (text: any, record: IPromotion) => {
        switch (record.redemption_value) {
          case "DI":
            return "Giảm giá";
          case "GI":
            return "Quà tặng";
        }
      },
      filters: [
        {
          text: "Giảm giá",
          value: "DI",
        },
        {
          text: "Quà tặng",
          value: "GI",
        },
      ],
      onFilter: (value: any, record: IPromotion) =>
        record.redemption_value.startsWith(value),
      filterSearch: true,
    },
    {
      title: t({ id: "app.promotion_tool.promotions.gift_type" }),
      key: "gift_type",
      render: (text: any, record: IPromotion) => {
        switch (record.gift_type) {
          case "PR":
            return "Product";
          case "CO":
            return "Combo";
        }
      },
      filters: [
        {
          text: "Product",
          value: "PR",
        },
        {
          text: "Combo",
          value: "CO",
        },
      ],
      onFilter: (value: any, record: IPromotion) =>
        record.gift_type.startsWith(value),
      filterSearch: true,
    },
    {
      title: t({ id: "app.promotion_tool.promotions.status" }),
      key: "status",
      render: (record: IPromotion) => {
        switch (record.status) {
          case "CR":
            return <Tag color="blue">Create</Tag>;
          case "SU":
            return <Tag color="blue">Submit</Tag>;
          case "AP":
            return <Tag color="blue">Approve</Tag>;
        }
      },
    },
    {
      title: "State",
      key: "state",
      render: (record: IPromotion) => {
        switch (record.state) {
          case "NE":
            return <Tag color="red">Không đủ kho code</Tag>;
          case "EX":
            return <Tag color="orange">Hết hạn</Tag>;
          case "RU":
            return <Tag color="blue">Đang chạy</Tag>;
          case "GE":
            return <Tag color="orange">Đang gen code</Tag>;
        }
      },
    },
    {
      title: t({ id: "app.promotion_tool.action" }),
      key: "action",
      render: (text: any, record: IPromotion) => {
        if (record.state === "RU") {
          return (
            <Button
              type="primary"
              onClick={() => {
                setVisibleCode(true);
                setIdPromotion(record.id);
              }}
            >
              Pick Code
            </Button>
          );
        } else {
          if (record.status === "AP") {
            return (
              <Button
                type="primary"
                onClick={() => genCodePromotion(record.id)}
                disabled={record.state === "GE" ? true : false}
              >
                Gen code
              </Button>
            );
          } else {
            if (record.status === "SU") {
              return (
                <Popconfirm
                  placement="left"
                  title={
                    <Form layout="vertical">
                      <Form.Item label="Vui lòng chọn ngày bắt đầu">
                        <DatePicker
                          placeholder="Enter start date"
                          onChange={(date, dateString) => {
                            //console.log(moment(dateString).format());
                            setStartDate(moment(dateString).format());
                          }}
                        />
                      </Form.Item>
                    </Form>
                  }
                  onConfirm={() => approvePromotion(record.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary">Approve</Button>
                </Popconfirm>
              );
            } else {
              return (
                <>
                  <Button
                    onClick={() => {
                      setIsModalUpdate(!isModalUpdate);
                      setDataUpdate(record);
                    }}
                    type="primary"
                    icon={<EditOutlined />}
                  />
                  <Button
                    style={{ marginLeft: 10, marginRight: 10 }}
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      deletePromotion(record.id);
                    }}
                  />
                </>
              );
            }
          }
        }
      },
    },
  ];

  return (
    <div>
      <Card
        loading={loading}
        title={t({ id: "app.promotion_tool.promotions.title" })}
        extra={
          <Button
            type="primary"
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            {t({ id: "app.promotion_tool.add" })}
          </Button>
        }
      >
        {/* <FormSearchPromotion /> */}
        <Table
          loading={loading}
          scroll={{ x: 2000 }}
          dataSource={data}
          columns={columns}
          tableLayout="auto"
        />
      </Card>
      <Modal
        width={1024}
        title={t({ id: "app.promotion_tool.promotions.modal.title" })}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <CreateFormPromotion fetchData={fetchListPromotion} />
      </Modal>
      <Modal
        width={1024}
        title={t({ id: "app.promotion_tool.promotions.modalUpdate.title" })}
        visible={isModalUpdate}
        footer={null}
        onCancel={handleCancelUpdate}
      >
        <FormUpdatePromotion fetchData={fetchListPromotion} data={dataUpdate} />
      </Modal>
      <Modal
        visible={visibleCode}
        footer={null}
        onCancel={() => {
          setVisibleCode(false);
        }}
      >
        <Spin tip="Loading..." spinning={loading}>
          <Form
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item name="amount" label="Amount">
              <InputNumber type="number" min={1} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item name="tags" label="Tags">
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label="Writer Email" name="writer_emails">
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Tags Mode"
              ></Select>
            </Form.Item>
            <Form.Item label="Reader Email" name="reader_emails">
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Tags Mode"
              ></Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
      <Modal
        onCancel={() => {
          setNotiGenCode(false);
        }}
        visible={notiGenCode}
        footer={null}
        title={null}
        bodyStyle={{ textAlign: "center" }}
      >
        <Spin size="large" />
        <div>
          <span>
            Hệ thống đang xử lý, Việc này sẽ mất nhiều thời gian. Nhấn 'X' để
            thoát khỏi thông báo!
          </span>
        </div>
      </Modal>
    </div>
  );
}
