import { Button, Card, Table, Modal, Form, Input, Spin, Tag } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useLocale } from "../../../locales";
import {
  apiListReportPromotion,
  apiReportPromotion,
} from "../../../services/promotion.api";
import { IPromotion, IPromotionReport } from "../../../types/promotion.type";
import moment from "moment";
import { RouteComponentProps, Link } from "@reach/router";
import { tableColumnTextFilterDateConfig } from "../../../utils/filterDateTable";
import { tableColumnTextFilterConfig } from "../../../utils/filterTable";
import styles from "./style.module.scss";
import useApp from "../../../context/app.context";

type Data = {
  key: string;
  name: string;
};

export default function PromotionReportScene(_: RouteComponentProps) {
  const { t } = useLocale();
  const {
    stateAuth: { chanelId },
  } = useApp();
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMessage, setIsMessage] = useState(true);

  const fetchListPromotion = useCallback(async () => {
    setLoading(true);
    const res: IPromotionReport[] = await apiListReportPromotion(
      1000,
      0,
      chanelId
    );
    if (res) {
      console.log(res);
      setLoading(false);
      const dataSource = res.map((r: any) => ({
        key: r.id,
        id: r.id,
        name: r.name,
        active: r.active,
        inactive: r.inactive,
        total: r.total,
        active_in_percent: r.active_in_percent,
        name_product: r.product?.name,
        redemption_type: r.redemption_type,
        redemption_value: r.redemption_value,
        discount_type: r.discount_type,
        discount_value: r.discount_value,
        max_flat_discount_value: r.max_flat_discount_value,
        expected_date: r.expected_date,
        expiry_date: r.expiry_date,
        state: r.state,
        status: r.status,
      }));
      setData(dataSource);
    }
  }, []);

  useEffect(() => {
    fetchListPromotion();
  }, [fetchListPromotion]);

  const onFinish = async (values: any) => {
    setLoading(true);
    if (selectedRowKeys.length > 10) {
      setIsModalVisible(false);
      return Modal.warning({
        title: "Warning",
        content: "You can select up to 10 items for earch export",
      });
    } else {
      const promotion_ids = selectedRowKeys.toString();
      await apiReportPromotion(
        {
          share_emails: [
            {
              email: values.writer,
              role: "writer",
            },
          ],
        },
        promotion_ids,
        chanelId
      )
        .then(() => {
          setIsModalVisible(false);
          Modal.success({
            title: "Success!",
            content: "Send email success!",
          });
        })
        .catch(() => {
          setIsModalVisible(false);
          Modal.error({
            title: "Error!",
            content: "Send email error!",
          });
        });
    }
    setLoading(false);
    setSelectedRowKeys([]);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const columns: any = [
    {
      title: t({ id: "app.promotion_tool.promotions.name" }),
      key: "name",
      fixed: "left",
      width: 300,
      render: (text: IPromotionReport) => (
        <Link to={`/promotions/${text.id}`}>
          <div className={styles.textEclips}>{text.name}</div>
        </Link>
      ),
      ...tableColumnTextFilterConfig<Data>(),
      onFilter: (value: any, record: any) => {
        return record.name
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    },
    {
      title: "Active",
      key: "active",
      dataIndex: "active",
      width: 80,
    },
    {
      title: "Inactive",
      key: "inactive",
      dataIndex: "inactive",
      width: 80,
    },
    {
      title: "All code",
      key: "total",
      dataIndex: "total",
      width: 80,
    },
    {
      title: "Rate active/all",
      key: "active_in_percent",
      render: (record: IPromotionReport) =>
        `${record.active_in_percent.toFixed(0)} %`,
      width: 100,
    },
    {
      title: "Name product",
      key: "name_product",
      dataIndex: "name_product",
      width: 300,
    },
    {
      title: t({ id: "app.promotion_tool.promotions.type_promotion" }),
      key: "redemption_type",
      render: (text: any, record: IPromotionReport) => {
        switch (record.redemption_type) {
          case "CO":
            return "Coupon";
          case "VO":
            return "Voucher";
        }
      },
      filters: [
        {
          text: "Coupon",
          value: "CO",
        },
        {
          text: "Voucher",
          value: "VO",
        },
      ],
      onFilter: (value: any, record: IPromotionReport) =>
        record.redemption_type.startsWith(value),
      filterSearch: true,
    },
    {
      title: "Promotion value",
      key: "redemption_value",
      render: (text: any, record: IPromotion) => {
        switch (record.redemption_value) {
          case "DI":
            return "Giảm giá";
          case "GI":
            return "Quà tặng";
        }
      },
      filters: [
        {
          text: "Giảm giá",
          value: "DI",
        },
        {
          text: "Quà tặng",
          value: "GI",
        },
      ],
      onFilter: (value: any, record: IPromotion) =>
        record.redemption_value.startsWith(value),
      filterSearch: true,
    },
    {
      title: t({ id: "app.promotion_tool.promotions.discount_type" }),
      key: "discount_type",
      render: (text: any, record: IPromotionReport) => {
        switch (record.discount_type) {
          case "FL":
            return "Tiền";
          case "PE":
            return "%";
        }
      },
      filters: [
        {
          text: "Tiền",
          value: "FL",
        },
        {
          text: "%",
          value: "PE",
        },
      ],
      onFilter: (value: any, record: IPromotionReport) =>
        record.discount_type.startsWith(value),
      filterSearch: true,
    },
    {
      title: t({ id: "app.promotion_tool.promotions.discount_value" }),
      key: "discount_value",
      dataIndex: "discount_value",
    },
    {
      title: t({ id: "app.promotion_tool.promotions.max_flat_discount_value" }),
      key: "max_flat_discount_value",
      dataIndex: "max_flat_discount_value",
    },
    {
      title: t({ id: "app.promotion_tool.promotions.status" }),
      key: "status",
      render: (record: IPromotion) => {
        switch (record.status) {
          case "CR":
            return <Tag color="blue">Create</Tag>;
          case "SU":
            return <Tag color="blue">Submit</Tag>;
          case "AP":
            return <Tag color="blue">Approve</Tag>;
        }
      },
    },
    {
      title: "State",
      key: "state",
      render: (record: IPromotion) => {
        switch (record.state) {
          case "NE":
            return <Tag color="red">Không đủ kho code</Tag>;
          case "EX":
            return <Tag color="orange">Hết hạn</Tag>;
          case "RU":
            return <Tag color="blue">Đang chạy</Tag>;
          case "GE":
            return <Tag color="orange">Đang gen code</Tag>;
        }
      },
    },
    {
      title: t({ id: "app.promotion_tool.promotions.expected_date" }),
      key: "expected_date",
      render: (text: IPromotionReport) =>
        moment(text.expected_date).format("L"),
      ...tableColumnTextFilterDateConfig<Data>(),
      onFilter: (value: any, record: IPromotionReport) => {
        return moment(record.expected_date)
          .format("L")
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    },
    {
      title: t({ id: "app.promotion_tool.promotions.expires_date" }),
      key: "expiry_date",
      render: (text: IPromotionReport) => moment(text.expiry_date).format("L"),
      ...tableColumnTextFilterDateConfig<Data>(),
      onFilter: (value: any, record: IPromotionReport) => {
        return moment(record.expiry_date)
          .format("L")
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    },
  ];
  return (
    <div style={{ position: "relative" }}>
      <Card
        loading={loading}
        title={
          selectedRowKeys.length > 0 ? (
            <div className={styles.flexItem}>
              <div className={styles.flexItem}>
                <Button danger onClick={() => setSelectedRowKeys([])}>
                  Clear All
                </Button>
                <div style={{ marginLeft: "20px" }}>
                  {selectedRowKeys.length} items selected
                </div>
              </div>

              <div>You can select up to 10 items for earch export</div>
              <Button
                type="primary"
                onClick={() => setIsModalVisible(true)}
                disabled={selectedRowKeys.length > 10}
              >
                Export to Email
              </Button>
            </div>
          ) : (
            t({ id: "app.promotion_tool.promotions.title" })
          )
        }
      >
        <div>
          <Table
            loading={loading}
            scroll={{ x: 2000, y: "80vh" }}
            dataSource={data}
            columns={columns}
            pagination={{
              defaultPageSize: 50,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            }}
            rowSelection={{
              type: "checkbox",
              columnWidth: 50,
              selectedRowKeys: selectedRowKeys,
              onChange: (id: any) => {
                setSelectedRowKeys(id);
              },
            }}
          />
        </div>
      </Card>

      <Modal
        title="Send Email"
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        footer={false}
      >
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <Spin />
            <br />
            <div>Hệ thống đang gửi email</div>
          </div>
        ) : (
          <Form
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <span> Exporting {selectedRowKeys.length} Promotions to email</span>
            <Form.Item
              label="Writer Email"
              name="writer"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input type="email" />
            </Form.Item>

            <Form.Item label="Reader Email" name="reader">
              <Input type="email" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
      {selectedRowKeys.length > 10 && (
        <Modal
          visible={isMessage}
          footer={false}
          onCancel={() => setIsMessage(false)}
        >
          <div>You can select up to 10 items for earch export</div>
        </Modal>
      )}
    </div>
  );
}
